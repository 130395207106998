import React from 'react'
import { TbBrandApple, TbBrandGooglePlay } from 'react-icons/tb'
import { Outlet } from 'react-router-dom'

import authWallpaper from '@/assets/images/auth-wallpaper.webp'
import { Snackbar } from '@/components'
import { useGlobalState } from '@/context'
import { Box, Button, Stack, Typography } from '@mui/material'

export const LayoutAuth = () => {
  const [{ snackbar }, dispatch] = useGlobalState()

  return (
    <Stack display='flex' width='100%' height='100vh' flexDirection='row'>
      <Stack
        flex={1}
        display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
      >
        <Stack
          sx={{
            flex: 1,
            position: 'relative',
            background: `url(${authWallpaper})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Stack
            position='absolute'
            bottom='30px'
            left='5%'
            width='90%'
            display='none'
          >
            <Typography variant='subtitle1'>
              Download the super app and enjoy
            </Typography>
            <Typography variant='subtitle1'>all the benefits</Typography>
            <Box mb={2} />
            <Stack direction='row' spacing={2}>
              <Button
                variant='outlined'
                color='inherit'
                startIcon={<TbBrandApple />}
              >
                App Store
              </Button>

              <Button
                variant='outlined'
                color='inherit'
                startIcon={<TbBrandGooglePlay />}
              >
                Google Play
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        flex={1}
        display={{ xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }}
      >
        <Outlet />
      </Stack>

      <Snackbar
        message={snackbar?.message}
        alertSeverity={snackbar?.alertSeverity}
        anchorOrigin={snackbar?.anchorOrigin}
        onCloseSnackbar={() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: undefined,
          })
        }}
      />
    </Stack>
  )
}
