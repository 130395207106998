import {
  QueryFieldFilterConstraint,
  QueryLimitConstraint,
  QueryOrderByConstraint,
  QueryStartAtConstraint,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore'

import { FirebaseCollection } from '@/constants/firebase-collection'
import { firestore } from '@/services/firebase'
import { delay } from '@/utils/tricks'

interface GetListProps {
  companyId: string
  setOrderList: (orderList: Order[] | undefined) => void
  setLoadingList: React.Dispatch<React.SetStateAction<boolean>>
  whereList?: QueryFieldFilterConstraint[]
  orderByList?: QueryOrderByConstraint[]
  startAfter?: QueryStartAtConstraint[]
  limit?: QueryLimitConstraint[]
  timeDelay?: number
}
export const getList = async ({
  companyId,
  setOrderList,
  setLoadingList,
  whereList,
  orderByList,
  startAfter,
  limit,
  timeDelay,
}: GetListProps) => {
  setLoadingList(true)

  if (timeDelay) await delay(timeDelay)

  const q = query(
    collection(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_ORDER,
    ),
    ...(whereList || []),
    ...(orderByList || []),
    ...(startAfter || []),
    ...(limit || []),
  )

  onSnapshot(
    q,
    querySnapshot => {
      const orderList: Order[] = []
      querySnapshot.forEach(doc => {
        const dataItem = { id: doc.id, ...doc.data() } as Order
        orderList.push(dataItem)
      })
      setLoadingList(false)
      setOrderList(orderList)
    },
    () => {
      console.log('order - getList() - error')
      setLoadingList(false)
    },
  )
}

interface GetOneProps {
  companyId: string
  orderId: string
}
export const getOne = async ({
  companyId,
  orderId,
}: GetOneProps): Promise<Order | undefined> => {
  const docRef = doc(
    firestore,
    FirebaseCollection.BASE_COMPANY,
    companyId,
    FirebaseCollection.BASE_ORDER,
    orderId,
  )
  const docSnap = await getDoc(docRef)

  try {
    if (docSnap.exists()) {
      return {
        id: orderId,
        ...docSnap.data(),
      } as Order
    }
  } catch (error) {
    return undefined
  }

  return undefined
}

interface GetLastSaleNumberProps {
  companyId: string
}
export const getLastSaleNumber = async ({
  companyId,
}: GetLastSaleNumberProps) => {
  const q = query(
    collection(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_ORDER,
    ),
    orderBy('saleNumber', 'desc'),
    limit(1),
  )

  const querySnapShot = await getDocs(q)

  if (querySnapShot.size === 0) return undefined

  const orderList: Order[] = []
  querySnapShot.forEach(doc => {
    orderList.push(doc.data() as Order)
  })

  return orderList[0]
}

interface GetLastInvoiceNumberProps {
  companyId: string
}
export const getLastInvoiceNumber = async ({
  companyId,
}: GetLastInvoiceNumberProps) => {
  const q = query(
    collection(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_ORDER,
    ),
    orderBy('invoiceNumber', 'desc'),
    limit(1),
  )

  const querySnapShot = await getDocs(q)

  if (querySnapShot.size === 0) return undefined

  const orderList: Order[] = []
  querySnapShot.forEach(doc => {
    orderList.push(doc.data() as Order)
  })

  return orderList[0]
}

interface SaveProps {
  companyId: string
  values: Partial<Order>
}
export const save = async ({ companyId, values }: SaveProps) =>
  await addDoc(
    collection(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_ORDER,
    ),
    {
      ...values,
      createdAt: new Date().getTime(),
      dateCreatedAt: new Date().setHours(0, 0, 0, 0),
      updatedAt: new Date().getTime(),
    },
  ).then(resp => resp.id)

interface UpdateProps {
  companyId: string
  orderId: string
  values: Partial<Order>
}
export const update = async ({ companyId, orderId, values }: UpdateProps) => {
  await setDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_ORDER,
      orderId,
    ),
    { ...values, updatedAt: new Date().getTime() },
    { merge: true },
  )
}
