/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  TbDotsVertical,
  TbPencil,
  TbPlus,
  TbSearch,
  TbTrash,
  TbX,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { limit, orderBy, QueryFieldFilterConstraint, startAfter, where } from 'firebase/firestore'

import {
  AvatarLetter,
  BoxShadow,
  Breadcrumb,
  DialogConfirm,
  LoadingTable,
  Status,
} from '@/components'
import { NoContent } from '@/components/no-content'
import { useGlobalState } from '@/context'
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import {
  Avatar,
  Box,
  Button,
  InputAdornment,
  ListItemIcon,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { grey, red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'

const LIMIT_ROWS = 50
const BOX_HEIGHT = 500
const END_REACHED_THRESHOLD_PERCENTAGE = 95

export const Client = () => {
  const refTable = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const [{ company }, dispatch] = useGlobalState()

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [clientSelected, setClientSelected] = useState<Client | undefined>(
    undefined,
  )
  const open = Boolean(anchorEl)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)

  const [clientList, setClientList] = useState<Client[] | undefined>(undefined)
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [stopInfinitScroll, setStopInfinitScroll] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'Client',
      title: 'Clients',
      isCurrentPage: true,
    },
  ]
  const [searchClient, setSearchClient] = useState<string>('')

  const hasSearchClient = useMemo(() => searchClient.trim().length > 0, [searchClient])

  const handleMenuAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    client: Client,
  ) => {
    setAnchorEl(event.currentTarget)
    setClientSelected(client)
  }

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const handleDeleteClient = async () => {
    if (!clientSelected) return
    setLoadingDelete(true)
    await ApiService.Client.remove({ companyId, clientId: clientSelected.id })
      .then(async () => {
        if (clientSelected.photoURL && clientSelected.photoName) {
          await ApiService.Client.removePhoto({
            companyId,
            clientId: clientSelected.id,
            photoName: clientSelected.photoName,
          })
        }
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Client deleted' },
        })
      })
      .catch(() => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Client was not deleted' },
        })
      })
      .finally(() => {
        setLoadingDelete(false)
      })
  }

  const getClient = (clientName?: string, isMore?: boolean) => {
    let whereList: QueryFieldFilterConstraint[] = []
    const orderByList = [orderBy('fullnameSearch', 'asc')]
    const limitRows = [limit(LIMIT_ROWS)]

    if (clientName && clientName.trim().length > 0) {
      whereList = [where('fullnameSearch', '>=', clientName.toUpperCase()), where('fullnameSearch', '<=', clientName.toUpperCase() + `\uf8ff`)]
    }

    if (isMore && clientList && clientList.length > 0) {
      const lastData = clientList[clientList.length - 1].fullnameSearch

      ApiService.Client.getList({
        companyId,
        setClientList: (moreList) => {
          if (!moreList || moreList.length === 0) {
            setStopInfinitScroll(true)
            dispatch({
              type: 'SET_SNACKBAR',
              data: {
                message: 'Last records loaded!',
                alertSeverity: 'warning',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
              }
            })
            return
          }
          setClientList([...clientList, ...moreList as Client[]])
        },
        setLoadingList: setLoadingMore,
        whereList,
        orderByList,
        limit: limitRows,
        startAfter: [startAfter(lastData)],
        timeDelay: 1000,
      })
      return
    }

    if (refTable.current) refTable.current.scrollTo(0, 0)

    ApiService.Client.getList({
      companyId,
      setClientList,
      setLoadingList,
      whereList,
      orderByList,
      limit: limitRows,
    })
    setStopInfinitScroll(false)
  }

  const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const {
      currentTarget: { scrollTop, clientHeight, scrollHeight },
    } = event
    const scrollArrivingToEnd = ((scrollTop + clientHeight) / scrollHeight) * 100

    if (clientList && clientList?.length > 0 && !loadingList && !stopInfinitScroll && scrollArrivingToEnd >= END_REACHED_THRESHOLD_PERCENTAGE) {
      getClient(searchClient, true)
    }
  }

  const renderTableBody = () => {
    if (loadingList) {
      return <LoadingTable />
    }

    if (clientList?.length === 0) {
      return (
        <Stack
          display='flex'
          alignItems='center'
          width='100%'
          position='absolute'
          mt={10}
        >
          <NoContent />
        </Stack>
      )
    }

    return (
      <TableBody>
        {clientList?.map(client => (
          <TableRow hover key={client.id}>
            <TableCell>
              <Stack flexDirection='row'>
                {client?.photoURL ? (
                  <Avatar src={client.photoURL} />
                ) : (
                  <AvatarLetter
                    name={(client.fullname || '')
                      .substring(0, 1)
                      .toUpperCase()}
                  />
                )}
                <Box marginRight={2} />
                <Stack justifyContent='center'>
                  <Typography variant='body2'>{client.fullname}</Typography>
                </Stack>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='column'>
                <Typography variant='body2'>
                  {client.email}
                </Typography>
                <Typography variant='caption' color={grey[500]}>
                  {client.emailSecondary || '-- --'}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='column'>
                <Typography variant='body2'>{client.phoneNumber}</Typography>
                <Typography variant='caption' color={grey[500]}>
                  {client.phoneNumberSecondary || '-- --'}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Status
                  title={client.isActive ? 'Active' : 'Inactive'}
                  type={client.isActive ? 'SUCCESS' : 'ERROR'}
                />
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => {
                      navigate(RoutesNames.CLIENT_CRUD, {
                        state: { clientSelected: JSON.stringify(client) },
                      })
                    }}
                  >
                    <TbPencil color={grey[900]} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Options'>
                  <IconButton
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={event => {
                      handleMenuAction(event, client)
                    }}
                  >
                    <TbDotsVertical color={grey[900]} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        ),
        )}
      </TableBody>
    )
  }

  useEffect(() => {
    getClient()
  }, [])

  // const handleNewField = async () => {
  //   if (!clientList || clientList?.length === 0) return

  //   console.log('QTD: ', clientList.length);

  //   clientList.forEach((client, idx) => {
  //     const temp = client
  //     ApiService.Client.update({
  //       companyId,
  //       clientId: temp.id,
  //       values: {
  //         ...client,
  //         fullnameSearch: `${temp.fullname || ''} ${temp.id || ''}`.toUpperCase()
  //       }
  //     }).then(() => {
  //       console.log('SUCESSO');
  //     }).catch(() => {
  //       console.log('ERROR');
  //     })
  //   });

  // }

  return (
    <Box>
      <Breadcrumb pageTitle='List of clients' menu={breadcrumbList}>
        <Stack display='flex' alignItems='center'>
          <Button
            startIcon={<TbPlus />}
            color='btnSecondary'
            variant='contained'
            size='small'
            onClick={() => {
              navigate(RoutesNames.CLIENT_CRUD)
            }}
            disableElevation
          >
            New
          </Button>
        </Stack>
      </Breadcrumb>

      <BoxShadow>
        <Stack
          display='flex'
          flex={1}
          flexDirection='row'
          justifyContent='center'
          padding={2}>
          <Stack display='flex' flexDirection='row' flex={1}>
            <TextField
              fullWidth
              size='small'
              label='Search by name'
              onChange={event => { setSearchClient(event.target.value) }}
              value={searchClient}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <TbSearch color={grey[900]} />
                  </InputAdornment>
                ),
              }}
              sx={{ width: 360 }}
            />
            <IconButton onClick={() => {
              getClient(searchClient)
            }}
              sx={{ marginLeft: 1 }}
              disabled={!hasSearchClient}
            >
              <TbSearch color={grey[hasSearchClient ? 900 : 400]} />
            </IconButton>
          </Stack>
          <Stack display='flex' justifyContent='center' flex={1}>
            <Stack alignSelf='flex-end'>
              <Button
                size='small'
                variant='outlined'
                disableElevation
                onClick={() => {
                  getClient()
                  setSearchClient('')
                }}
                sx={{ display: 'flex', width: 'auto' }}
                disabled={!hasSearchClient}
              >
                Clean
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <TableContainer
          ref={refTable}
          sx={{ height: BOX_HEIGHT }}
          onScroll={(event) => {
            if (stopInfinitScroll) return
            onScroll(event)
          }}
        >
          <Table stickyHeader sx={{ position: 'relative' }} size='small'>
            <TableHead>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Typography variant='subtitle2'>Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>E-mail</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Phone number</Typography>
                </TableCell>
                <TableCell width={100}>
                  <Typography variant='subtitle2'>Status</Typography>
                </TableCell>
                <TableCell width={50} />
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
          {loadingMore && <LoadingTable height={100} position='relative' />}
        </TableContainer>
      </BoxShadow >

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            navigate(RoutesNames.CLIENT_CRUD, {
              state: { clientSelected: JSON.stringify(clientSelected) },
            })
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            setIsOpenConfirmDelete(true)
          }}
        >
          <ListItemIcon>
            <TbTrash size={20} color={red[500]} />
          </ListItemIcon>
          <Typography variant='body2' color={red[500]}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${clientSelected?.fullname}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => {
            handleDeleteClient()
          },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setIsOpenConfirmDelete(false)
          },
        }}
      />
    </Box >
  )
}
