import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  TbDotsVertical,
  TbPencil,
  TbPrinter,
  TbSearch,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import { limit, orderBy, QueryFieldFilterConstraint, startAfter, where } from 'firebase/firestore'
import moment from 'moment'


import { BoxShadow, Breadcrumb, DateRange, LoadingTable } from '@/components'
import { NoContent } from '@/components/no-content'
import { useGlobalState } from '@/context'
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { formatNumberToLibra } from '@/utils/currency'
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { green, grey } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'

import { PrintCredit } from './print-credit'

const LIMIT_ROWS = 50
const BOX_HEIGHT = 500
const END_REACHED_THRESHOLD_PERCENTAGE = 95

// const startMoment = moment()
// const endMoment = moment()
// const START_DATE = startMoment.subtract(30, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf()
// const END_DATE = endMoment.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).valueOf()

enum ENUM_ORDER_BY {
  NAME = 'Client name',
  IS_USED = 'Credit used',
  KEY = 'Key',
}
type ORDER_BY_TYPE = keyof typeof ENUM_ORDER_BY
type SEARCH_CREDIT_IS_USDE = 'USED' | 'NOT_USED' | ''
interface DataPrintOrder {
  client: Client
  clientCredit: ClientCredit
  service: Service
  order: Order
}

export const OrderCreditNote = () => {
  const refTable = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const printCreditRef = useRef<HTMLDivElement>(null)
  const [{ company }, dispatch] = useGlobalState()

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [creditSelected, setCreditSelected] = useState<ClientCredit | undefined>(
    undefined,
  )
  const open = Boolean(anchorEl)

  const [clientCreditList, setClientCreditList] = useState<ClientCredit[] | undefined>(undefined)
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [stopInfinitScroll, setStopInfinitScroll] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const [filterBy, setFilterBy] = useState<ORDER_BY_TYPE | ''>('')
  const breadcrumbList: Breadcrumb[] = [
    {
      id: RoutesNames.HOME,
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'Credits_Notes',
      title: 'Credits notes',
      isCurrentPage: true,
    },
  ]
  const [searchCredit, setSearchCredit] = useState<string>('')
  const [searchCreditIsUsed, setSearchCreditIsUsed] = useState<SEARCH_CREDIT_IS_USDE>('')
  const [printCredit, setPrintCredit] = useState<DataPrintOrder | undefined>(undefined)
  const [startDate, setStartDate] = useState<null | number>(null)
  const [endDate, setEndDate] = useState<null | number>(null)
  const [showEndDate, setShowEndDate] = useState<boolean>(false)

  const filterCreditList = useMemo(
    () => {
      if (!clientCreditList || clientCreditList.length === 0) return [] as ClientCredit[]

      return clientCreditList.filter(credit => {
        if (filterBy === 'KEY') {
          return Number(credit.idIncrement || 0) === Number(searchCredit)
        }
        if (filterBy === 'NAME') {
          return credit.clientName.toLowerCase().includes(searchCredit.toLowerCase())
        }

        if (filterBy === 'IS_USED' && searchCreditIsUsed) {
          return credit.isUsed === (searchCreditIsUsed === 'USED')
        }

        return credit
      })
    },
    [clientCreditList, searchCredit, searchCreditIsUsed],
  )
  const hasFilterDates = useMemo(() => {
    if (startDate && endDate) return true
    return false
  }, [startDate, endDate])
  const hasFitlerBy = useMemo(() => {
    if (filterBy) return true
    return false
  }, [filterBy])
  const hasSomeFilter = useMemo(() => {
    if (filterBy && searchCredit.trim().length > 0 || (startDate && endDate)) return true
    return false
  }, [filterBy, searchCredit, startDate, endDate])

  const handleMenuAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    credit: ClientCredit,
  ) => {
    setAnchorEl(event.currentTarget)
    setCreditSelected(credit)
  }

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const renderTableBody = () => {
    if (loadingList) {
      return <LoadingTable />
    }

    if (filterCreditList?.length === 0) {
      return (
        <Stack
          display='flex'
          alignItems='center'
          width='100%'
          position='absolute'
          mt={10}
        >
          <NoContent />
        </Stack>
      )
    }

    return (
      <TableBody>
        {filterCreditList?.map(credit => {
          let backgroundColor = ''
          if (credit.isUsed) {
            backgroundColor = green[50]
          } else {
            backgroundColor = '#FFF'
          }

          return (
            <TableRow hover key={credit.id} sx={{ backgroundColor }}><TableCell>
              <Typography variant='body2'>{credit.idIncrement}</Typography>
            </TableCell>
              <TableCell>
                <Typography variant='body2'>{credit.clientName}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body2' textOverflow='ellipsis' noWrap width={300}>{credit.observation}</Typography>
              </TableCell>
              <TableCell>
                <Stack flexDirection='column'>
                  <Typography variant='body2'>
                    {formatNumberToLibra({ value: credit.amount, showCurrency: true })}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack flexDirection='column'>
                  <Typography variant='body2'>{moment(credit.createdAt).format('DD/MM/YYYY')}</Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack flexDirection='row'>
                  <Tooltip title='Edit'>
                    <IconButton
                      onClick={() => {
                        navigate(RoutesNames.ORDER_CREDIT_NOTE_CRUD, {
                          state: { creditSelected: JSON.stringify(credit) },
                        })
                      }}
                    >
                      <TbPencil color={grey[900]} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Options'>
                    <IconButton
                      id='basic-button'
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={event => {
                        handleMenuAction(event, credit)
                      }}
                    >
                      <TbDotsVertical color={grey[900]} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  const handleChangeOrderBy = (event: SelectChangeEvent) => {
    setFilterBy(event.target.value as ORDER_BY_TYPE)
    setSearchCredit('')
    setSearchCreditIsUsed('')
  }

  const handlePrint = async () => {
    const client = await ApiService.Client.getOne({
      companyId,
      clientId: creditSelected?.clientId || '',
    })

    const order = await ApiService.Order.getOne({
      companyId,
      orderId: creditSelected?.orderId || '',
    })

    const service = await ApiService.Service.getOne({
      companyId,
      serviceId: order?.serviceId || '',
    })

    if (!client || !service || !order || !creditSelected) return

    setPrintCredit({ client, service, order, clientCredit: creditSelected })
  }

  const handleShowPrint = useReactToPrint({
    content: () => printCreditRef.current,
    documentTitle: `print_credit_note_${new Date().getTime()}`,
    onBeforePrint: () => {
    },
    onAfterPrint: () => {
      setPrintCredit(undefined)
    },
    removeAfterPrint: true,
  })

  const getCreditNote = (
    dateStart: number | null,
    dateEnd: number | null,
    isMore?: boolean
  ) => {
    let whereList: QueryFieldFilterConstraint[] = []
    const orderByList = [orderBy('createdAt', 'desc')]
    const limitRows = [limit(LIMIT_ROWS)]

    if (dateStart && dateEnd) {
      whereList = [where('createdAt', '>=', dateStart), where('createdAt', '<=', dateEnd)]
    }

    if (isMore && clientCreditList && clientCreditList.length > 0) {
      const lastData = clientCreditList[clientCreditList.length - 1].createdAt
      ApiService.ClientCredits.getList({
        companyId,
        setClientCreditList: (moreList) => {
          if (!moreList || moreList.length === 0) {
            setStopInfinitScroll(true)
            dispatch({
              type: 'SET_SNACKBAR',
              data: {
                message: 'Last records loaded!',
                alertSeverity: 'warning',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
              }
            })
            return
          }
          setClientCreditList([...clientCreditList, ...moreList])
        },
        setLoadingList: setLoadingMore,
        whereList,
        orderByList,
        limit: limitRows,
        startAfter: [startAfter(lastData)],
        timeDelay: 1000,
      })
      return
    }

    if (refTable.current) refTable.current.scrollTo(0, 0)

    ApiService.ClientCredits.getList({
      companyId,
      setClientCreditList,
      setLoadingList,
      whereList,
      orderByList,
      limit: limitRows,
    })
    setStopInfinitScroll(false)
  }

  const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const {
      currentTarget: { scrollTop, clientHeight, scrollHeight },
    } = event
    const scrollArrivingToEnd = ((scrollTop + clientHeight) / scrollHeight) * 100

    if (clientCreditList && clientCreditList?.length > 0 && !loadingList && !stopInfinitScroll && scrollArrivingToEnd >= END_REACHED_THRESHOLD_PERCENTAGE) {
      getCreditNote(startDate, endDate, true)
    }
  }

  useEffect(() => {
    getCreditNote(startDate, endDate)
  }, [])

  useEffect(() => {
    if (!printCredit) return
    handleShowPrint(null, () => printCreditRef.current)
  }, [printCredit])

  return (
    <Box>
      <Breadcrumb pageTitle='Credits notes' menu={breadcrumbList} />

      <BoxShadow>
        <Stack display='flex' flex={1} flexDirection='row' justifyContent='space-between' padding={2}>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            showEndDate={showEndDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setShowEndDate={setShowEndDate}
            onSearch={() => {
              if (!startDate || !endDate) return
              getCreditNote(startDate, endDate)
            }}
            disabledBtnSearch={!hasFilterDates}
          />

          <Stack display='flex' flexDirection='row'>
            <FormControl size='small'>
              <InputLabel id='filterBy'>Filter by</InputLabel>
              <Select
                labelId='filterBy'
                value={filterBy}
                label='Filter by'
                onChange={handleChangeOrderBy}
                sx={{ width: 180 }}
              >
                <MenuItem value=''>
                  -- --
                </MenuItem>
                {Object.entries(ENUM_ORDER_BY).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack marginTop={1} mx={0.2}>-</Stack>
            {filterBy !== 'IS_USED' && (
              <TextField
                size='small'
                label='Search'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <TbSearch color={grey[900]} />
                    </InputAdornment>
                  ),
                }}
                value={searchCredit}
                onChange={event => {
                  setSearchCredit(event.target.value)
                }}
                sx={{ width: 180 }}
                type={filterBy === 'KEY' ? 'number' : 'text'}
                disabled={!hasFitlerBy}
              />
            )}
            {filterBy === 'IS_USED' && (
              <FormControl size='small'>
                <InputLabel id='filterBy'>Select</InputLabel>
                <Select
                  labelId='IsUsed'
                  value={searchCreditIsUsed}
                  label='Select'
                  onChange={(event: SelectChangeEvent) => {
                    setSearchCredit('')
                    setSearchCreditIsUsed(event.target.value as SEARCH_CREDIT_IS_USDE)
                  }}
                  sx={{ width: 180 }}
                >
                  <MenuItem value=''>
                    -- --
                  </MenuItem>
                  <MenuItem key='ds' value='USED'>
                    Is used
                  </MenuItem>
                  <MenuItem value='NOT_USED'>
                    Not used
                  </MenuItem>
                </Select>
              </FormControl>
            )}
            <Button
              size='small'
              variant='outlined'
              disableElevation
              onClick={() => {
                setFilterBy('')
                setSearchCredit('')
                setSearchCreditIsUsed('')
                setStartDate(null)
                setEndDate(null)
                getCreditNote(null, null)
              }}
              sx={{ height: 37, ml: 1 }}
              disabled={!hasSomeFilter}
            >
              Clean
            </Button>
          </Stack>
        </Stack>

        <TableContainer
          ref={refTable}
          sx={{ height: BOX_HEIGHT }}
          onScroll={(event) => {
            if (stopInfinitScroll) return
            onScroll(event)
          }}
        >
          <Table stickyHeader sx={{ position: 'relative' }} size='small'>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell width={80} >
                  <Typography variant='subtitle2'>Key</Typography>
                </TableCell>
                <TableCell >
                  <Typography variant='subtitle2'>Client name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Observations</Typography>
                </TableCell>
                <TableCell width={120}>
                  <Typography variant='subtitle2'>Amount</Typography>
                </TableCell>
                <TableCell width={120}>
                  <Typography variant='subtitle2'>Created at</Typography>
                </TableCell>
                <TableCell width={50} />
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
          {loadingMore && <LoadingTable height={100} position='relative' />}
        </TableContainer>
      </BoxShadow>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            navigate(RoutesNames.ORDER_CREDIT_NOTE_CRUD, {
              state: { creditSelected: JSON.stringify(creditSelected) },
            })
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            handlePrint()
          }}
        >
          <ListItemIcon>
            <TbPrinter size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'  >
            Print credit note
          </Typography>
        </MenuItem>
      </Menu>

      {printCredit?.client && printCredit?.service && printCredit?.order && (
        <div
          style={{ visibility: 'hidden', height: 0 }}>
          <PrintCredit
            ref={printCreditRef}
            client={printCredit.client}
            clientCredit={printCredit.clientCredit}
            service={printCredit.service}
            order={printCredit.order}
          />
        </div>
      )}
    </Box>
  )
}
