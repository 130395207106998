
import React from 'react'
import { Outlet } from 'react-router-dom'

import { Snackbar } from '@/components'
import { useGlobalState } from '@/context'
import { Stack } from '@mui/material'

export const LayoutMobile = () => {
  const [{ snackbar }, dispatch] = useGlobalState()

  return (
    <Stack display='flex' width='100%' height='100vh'>

      <Outlet />

      {/* <Stack
        flex={1}
        display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
         
      >
        <Stack
          sx={{
            flex: 1,
            position: 'relative',
            background: `url(${authWallpaper})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <Stack position='absolute' bottom='30px' left='5%' width='90%' >
            <Typography variant='subtitle1'>Download the super app and enjoy</Typography>
            <Typography variant='subtitle1'>all the benefits</Typography>
            <Box mb={2} />
            <Stack direction='row' spacing={2}>
              <Button
                variant='outlined'
                color='inherit'
                startIcon={<TbBrandApple />}
              >
                App Store
              </Button>

              <Button
                variant='outlined'
                color='inherit'
                startIcon={<TbBrandGooglePlay />}
              >
                Google Play
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        flex={1}
        display={{ xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }}
      >
        <Outlet />
      </Stack>
 
      */}

      <Snackbar
        message={snackbar?.message}
        alertSeverity={snackbar?.alertSeverity}
        anchorOrigin={snackbar?.anchorOrigin}
        // TransitionComponent={Slide}
        onCloseSnackbar={() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: undefined
          })
        }}
      />
    </Stack>
  )
}
