import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  TbCheck,
  TbDotsVertical,
  TbFileTypeXls,
  TbPencil,
  TbPrinter,
  TbSearch,
  TbSwitch3,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import { saveAs } from 'file-saver';
import { limit, orderBy, QueryFieldFilterConstraint, startAfter, where } from 'firebase/firestore'
import { Form, FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import * as XLSX from 'sheetjs-style';

import { BoxShadow, Breadcrumb, DateRange, DialogConfirm, LoadingTable, StatusOrder } from '@/components'
import { NoContent } from '@/components/no-content'
import { EnumOrderStatus } from '@/constants/order'
import { useGlobalState } from '@/context'
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { discontPercent, formatNumberToLibra, truncateByDecimalPlace } from '@/utils/currency'
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { green, grey, red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { PrintOrder } from './print-order'
import { validationSchemaInvoiceSummary } from './validationSchema';

const LIMIT_ROWS = 50
const BOX_HEIGHT = 500
const END_REACHED_THRESHOLD_PERCENTAGE = 95

// const startMoment = moment()
// const endMoment = moment()
// const START_DATE = startMoment.subtract(30, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf()
// const END_DATE = endMoment.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).valueOf()


enum ENUM_ORDER_BY {
  QUOTATION_NUMBER = 'Quotation number',
  SALE_NUMBER = 'Sale number',
  INVOICE_NUMBER = 'Invoice number',
  FLOW = 'Flow',
}
type ORDER_BY_TYPE = keyof typeof ENUM_ORDER_BY

interface DataPrintOrder {
  client: Client
  service: Service
  order: Order
}

interface InitialValueInvoiceSummary {
  startDate: null | number
  endDate: null | number
}
const INITIAL_VALUES_INVOICE_SUMARY: InitialValueInvoiceSummary = {
  startDate: null,
  endDate: null,
}

export const Order = () => {
  const refTable = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const [{ company }, dispatch] = useGlobalState()
  const printOrderRef = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [orderSelected, setOrderSelected] = useState<Order | undefined>(
    undefined,
  )
  const open = Boolean(anchorEl)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)
  const [isOpenApproveQuotation, setIsOpenApproveQuotation] = useState<boolean>(false)
  const [isOpenPrintInvoiceSummary, setIsOpenPrintInvoiceSummary] = useState<boolean>(false)
  const [loadingPrintInvoiceSummary, setLoadingPrintInvoiceSummary] = useState<boolean>(false)
  const [isOpenConfirmChangeOrderType, setIsOpenConfirmChangeOrderType] = useState<boolean>(false)
  const [orderListPrintSummary, setOrderListPrintSummary] = useState<Order[] | undefined>(undefined)
  const [orderList, setOrderList] = useState<Order[] | undefined>(undefined)
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [stopInfinitScroll, setStopInfinitScroll] = useState<boolean>(false)
  const [loadingDelete] = useState<boolean>(false)
  const [loadingApproveQuotation, setLoadingApproveQuotation] = useState<boolean>(false)
  const [loadingChangeOrderType, setLoadingChangeOrderType] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const [filterBy, setFilterBy] = useState<ORDER_BY_TYPE | ''>('')
  const [filterFlowBy, setFilterFlowBy] = useState<OrderStatus | undefined>(undefined)
  const [breadcrumbList, setBreadcrumbList] = useState<Breadcrumb[]>([])
  const [printOrder, setPrintOrder] = useState<DataPrintOrder | undefined>(undefined)
  const [startDate, setStartDate] = useState<null | number>(null)
  const [endDate, setEndDate] = useState<null | number>(null)
  const [showEndDate, setShowEndDate] = useState<boolean>(false)
  const [searchOrder, setSearchOrder] = useState<string>('')

  const formikFilterInvoiceSummary = useFormik({
    initialValues: INITIAL_VALUES_INVOICE_SUMARY,
    enableReinitialize: true,
    validationSchema: validationSchemaInvoiceSummary,
    onSubmit: async (values) => {
      setLoadingPrintInvoiceSummary(true)

      ApiService.Order.getList({
        companyId,
        setOrderList: (list) => {
          if ((list || []).length === 0) {
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'There is no data for the selected filter', alertSeverity: 'warning' },
            })
          }
          setOrderListPrintSummary(list)
        },
        setLoadingList: setLoadingPrintInvoiceSummary,
        whereList: [where('dateCreatedAt', '>=', values.startDate), where('dateCreatedAt', '<=', values.endDate)],
        orderByList: [orderBy('dateCreatedAt', 'asc')],
      })
    },
  })
  const orderType = useMemo(() => {
    const { pathname } = location

    let type: OrderType

    if (pathname === RoutesNames.ORDER_QUOTATION) {
      type = 'QUOTATION'
      setBreadcrumbList([
        {
          id: RoutesNames.HOME,
          title: 'Dashboard',
          href: RoutesNames.HOME,
        },
        {
          id: 'Order',
          title: 'Quatations',
          isCurrentPage: true,
        }
      ])

    } else if (pathname === RoutesNames.ORDER_SALE) {
      type = 'SALE'
      setBreadcrumbList([
        {
          id: RoutesNames.HOME,
          title: 'Dashboard',
          href: RoutesNames.HOME,
        },
        {
          id: 'Sales',
          title: 'Sales',
          isCurrentPage: true,
        }
      ])
    } else {
      type = 'INVOICE_AND_CREDIT'
      setBreadcrumbList([
        {
          id: RoutesNames.HOME,
          title: 'Dashboard',
          href: RoutesNames.HOME,
        },
        {
          id: 'InvoiceCredits',
          title: 'Invoices & Credits',
          isCurrentPage: true,
        }
      ])
    }

    return type
  }, [location.pathname])

  const filterOrderList = useMemo(() => {
    if (!orderList || orderList.length === 0) return [] as Order[]
    const listTemp = orderList.filter((order) => order.type === orderType)

    return listTemp?.filter(order => {
      if (filterBy === 'QUOTATION_NUMBER') {
        if (!searchOrder) return order
        return order.id.substring(0, 6).toUpperCase().includes(searchOrder.toUpperCase())
      }
      if (filterBy === 'SALE_NUMBER') {
        if (!searchOrder) return order
        return order.saleNumber === Number(searchOrder)

      }
      else if (filterBy === 'INVOICE_NUMBER') {
        if (!searchOrder) return order
        return order?.invoiceNumber === Number(searchOrder)
      }

      if (!filterFlowBy) return order

      return order.status === filterFlowBy
    })
  },
    [orderList, searchOrder, filterFlowBy],
  )
  const routesNameByOrderType = useMemo(() => {
    if (orderType === 'QUOTATION') {
      return RoutesNames.ORDER_QUOTATION
    } else if (orderType === 'SALE') {
      return RoutesNames.ORDER_SALE
    } else {
      return RoutesNames.ORDER_INVOICE_CREDIT
    }
  }, [orderType])
  const hasFilterDates = useMemo(() => {
    if (startDate && endDate) return true
    return false
  }, [startDate, endDate])
  const hasFilterBy = useMemo(() => {
    if (filterBy) return true
    return false
  }, [filterBy])
  const hasSomeFilter = useMemo(() => {
    if (filterBy && searchOrder.trim().length > 0 || (startDate && endDate)) return true
    return false
  }, [filterBy, searchOrder, startDate, endDate])

  const handleMenuAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    order: Order,
  ) => {
    setAnchorEl(event.currentTarget)
    setOrderSelected(order)
  }

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const renderTableBody = () => {
    if (loadingList) {
      return <LoadingTable />
    }

    if (filterOrderList?.length === 0) {
      return (
        <Stack
          display='flex'
          alignItems='center'
          width='100%'
          position='absolute'
          mt={10}
        >
          <NoContent />
        </Stack>
      )
    }

    return (
      <TableBody>
        {filterOrderList?.map((order) => {
          let backgroundColor = ''
          const isAfter = moment(order.paymentDue).isAfter(moment())

          if (order.paymentRef) {
            backgroundColor = green[50]
          } else if (order.paymentDue && !isAfter) {
            backgroundColor = red[50]
          } else {
            backgroundColor = '#FFF'
          }

          return (
            <TableRow hover key={order.id} sx={{ backgroundColor }}>
              <TableCell>
                <Stack flexDirection='column'>
                  <Typography variant='body2'
                    textOverflow='ellipsis'
                    noWrap
                    width={100}
                  >
                    {order.serviceTitle}
                  </Typography>
                  <Typography variant='caption' color={grey[500]} fontWeight={500}>
                    Key: {order.serviceId.substring(0, 6).toUpperCase()}
                  </Typography>
                </Stack>
              </TableCell>

              <TableCell>
                <Typography variant='body2'>{order.saleNumber || '-- --'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body2'>{order?.invoiceNumber || '-- --'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body2'>
                  {moment(order.createdAt).format('DD/MM/YYYY')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body2'>
                  <StatusOrder orderStatus={order.status} title={EnumOrderStatus[order.status]} />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body2'
                  textOverflow='ellipsis'
                  noWrap
                  width={100}
                >{order.clientName}</Typography>
              </TableCell>
              <TableCell>
                <Stack flexDirection='column'>
                  <Typography variant='body2'>
                    {formatNumberToLibra({
                      value: order.totalPrice || 0,
                      showCurrency: true,
                    })}
                  </Typography>

                  <Typography variant='caption' color={grey[500]} fontWeight={500}>
                    Work hour: {order.workHours}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack flexDirection='column'>
                  <Typography variant='body2' textOverflow='ellipsis' noWrap width={120}>
                    Ref: {order?.paymentRef || '-- --'}
                  </Typography>
                  <Typography variant='caption' color={grey[500]} fontWeight={500}>
                    Due: {order?.paymentDue ? moment(order.paymentDue).format('DD/MM/YYYY') : '-- --'}
                  </Typography>
                </Stack>

              </TableCell>
              <TableCell>
                <Stack flexDirection='row'>
                  <Tooltip title='Edit'>
                    <IconButton
                      onClick={() => {
                        navigate(RoutesNames.ORDER_CRUD, {
                          state: {
                            orderSelected: JSON.stringify(order),
                            from: routesNameByOrderType
                          },
                        })
                      }}
                    >
                      <TbPencil color={grey[900]} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Options'>
                    <IconButton
                      id='basic-button'
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={event => {
                        handleMenuAction(event, order)
                      }}
                    >
                      <TbDotsVertical color={grey[900]} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  const handleChangeOrderBy = (event: SelectChangeEvent) => {
    setFilterFlowBy(undefined)
    setSearchOrder('')
    setFilterBy(event.target.value as ORDER_BY_TYPE)
  }

  const handleChangeFilterFlowBy = (event: SelectChangeEvent) => {
    setFilterFlowBy(event.target.value as OrderStatus)
  }

  const handleChangeOrderType = async () => {
    if (!orderSelected) return

    setLoadingChangeOrderType(true)

    let type: OrderType = 'SALE'
    if (orderSelected.type === 'SALE') type = 'INVOICE_AND_CREDIT'

    let response: Order | undefined
    let newSaleNumber = null
    let newInvoiceNumber = null

    if (type === 'SALE') {
      response = await ApiService.Order.getLastSaleNumber({ companyId }).then((resp) => resp)
      if (!response?.saleNumber) {
        const company = await ApiService.Company.getOne({ companyId })
        if (!company?.lastSaleNumber) {
          setLoadingChangeOrderType(false)
          setIsOpenConfirmChangeOrderType(false)
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'There is no Sale Number registered', alertSeverity: 'error' },
          })
          return
        } else {
          newSaleNumber = company.lastSaleNumber + 1
        }
      } else {
        newSaleNumber = response.saleNumber + 1
      }
    }

    if (type === 'INVOICE_AND_CREDIT') {
      response = await ApiService.Order.getLastInvoiceNumber({ companyId }).then((resp) => resp)
      if (!response?.invoiceNumber) {
        const company = await ApiService.Company.getOne({ companyId })
        if (!company?.lastInvoiceNumber) {
          setLoadingChangeOrderType(false)
          setIsOpenConfirmChangeOrderType(false)
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'There is no Invoice Number registered', alertSeverity: 'error' },
          })
          return
        } else {
          newInvoiceNumber = company.lastInvoiceNumber + 1
        }
      } else {
        newInvoiceNumber = response.invoiceNumber + 1
      }
    }

    if (!newSaleNumber && !newInvoiceNumber) {
      setLoadingChangeOrderType(false)
      setIsOpenConfirmChangeOrderType(false)
      dispatch({
        type: 'SET_SNACKBAR',
        data: { message: 'There is no Sale and Invoice Number registered', alertSeverity: 'error' },
      })
      return
    }

    const data = {
      type,
      ...(type === 'SALE' ? {
        saleNumber: newSaleNumber,
        saleNumberCreatedAt: new Date().getTime()
      } : {}),
      ...(type === 'INVOICE_AND_CREDIT' ? {
        invoiceNumber: newInvoiceNumber,
        invoiceNumberCreatedAt: new Date().getTime(),
        paymentDue: moment().add(30, 'days').valueOf()
      } : {})
    } as Partial<Order>

    await ApiService.Order.update({
      companyId,
      orderId: orderSelected.id,
      values: data
    })
      .then(async () => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Success changed' },
        })
      })
      .catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error on change type', alertSeverity: 'error' },
        })
      })
      .finally(() => {
        setIsOpenConfirmChangeOrderType(false)
        setLoadingChangeOrderType(false)
      })
  }

  const handlePrint = async () => {
    const client = await ApiService.Client.getOne({
      companyId,
      clientId: orderSelected?.clientId || '',
    })

    const service = await ApiService.Service.getOne({
      companyId,
      serviceId: orderSelected?.serviceId || '',
    })

    const order = await ApiService.Order.getOne({
      companyId,
      orderId: orderSelected?.id || '',
    })

    if (!client || !service || !order) return

    setPrintOrder({ client, service, order })
  }

  const handleShowPrint = useReactToPrint({
    content: () => printOrderRef.current,
    documentTitle: `print_order_${new Date().getTime()}`,
    onBeforePrint: () => {
    },
    onAfterPrint: () => {
      setPrintOrder(undefined)
    },
    removeAfterPrint: true,
  })

  const handleApproveQuotation = async () => {
    setLoadingApproveQuotation(true)
    if (!orderSelected) return

    const service = await ApiService.Service.getOne({ companyId, serviceId: orderSelected.serviceId })

    const partsSold = JSON.parse(service?.partsSold || '') as StockWithCheck[]
    if (partsSold.length === 0) {
      setLoadingApproveQuotation(false)
      return
    }

    let thereAreProblemFromStock = false

    // eslint-disable-next-line no-unreachable-loop
    for (const part of partsSold) {
      const partFromStock = await ApiService.Stock.getOne({ companyId, stockId: part.id })

      if (!partFromStock) {
        thereAreProblemFromStock = true
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'The part has been removed from stock', alertSeverity: 'error' }
        })
        setLoadingApproveQuotation(false)
        return
      }

      if (partFromStock.quantity < part.howManyQuantity) {
        thereAreProblemFromStock = true
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: `There is not enough quantity in stock for the part: ${part.title}`, alertSeverity: 'error' }
        })
        setLoadingApproveQuotation(false)
        return
      }
    }

    if (thereAreProblemFromStock) return

    ApiService.Order.update({
      companyId,
      orderId: orderSelected?.id || '',
      values: { status: 'ACCEPTED' }
    }).then(() => {
      ApiService.Service.update({
        companyId,
        serviceId: orderSelected?.serviceId || '',
        values: { status: 'APPROVED' }
      }).then(async () => {

        await ApiService.Stock.updateInBatch({
          companyId,
          listStock: partsSold,
        })
          .then(() => {
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'Approved' }
            })
          })
          .catch(() => {
            dispatch({
              type: 'SET_SNACKBAR',
              data: {
                message: 'Error on change stock quantity',
                alertSeverity: 'error',
              },
            })
          })
      }).catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error on update service', alertSeverity: 'error' }
        })
      })
    }).catch(() => {
      dispatch({
        type: 'SET_SNACKBAR',
        data: { message: 'Error on update order', alertSeverity: 'error' }
      })
    }).finally(() => {
      setLoadingApproveQuotation(false)
      setIsOpenApproveQuotation(false)
    })
  }

  const getOrder = (
    dateStart: number | null,
    dateEnd: number | null,
    isMore?: boolean
  ) => {
    let whereList: QueryFieldFilterConstraint[] = []
    const orderByList = [orderBy('createdAt', 'desc')]
    const limitRows = [limit(LIMIT_ROWS)]

    if (dateStart && dateEnd) {
      whereList = [where('createdAt', '>=', dateStart), where('createdAt', '<=', dateEnd)]
    }

    if (isMore && orderList && orderList.length > 0) {
      const lastData = orderList[orderList.length - 1].createdAt
      ApiService.Order.getList({
        companyId,
        setOrderList: (moreList) => {
          if (!moreList || moreList.length === 0) {
            setStopInfinitScroll(true)
            dispatch({
              type: 'SET_SNACKBAR',
              data: {
                message: 'Last records loaded!',
                alertSeverity: 'warning',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
              }
            })
            return
          }
          setOrderList([...orderList, ...moreList])
        },
        setLoadingList: setLoadingMore,
        whereList,
        orderByList,
        limit: limitRows,
        startAfter: [startAfter(lastData)],
        timeDelay: 1000,
      })
      return
    }

    if (refTable.current) refTable.current.scrollTo(0, 0)

    ApiService.Order.getList({
      companyId,
      setOrderList: list => {
        setOrderList(list)
      },
      setLoadingList,
      whereList,
      orderByList,
      limit: limitRows,
    })
    setStopInfinitScroll(false)
  }

  const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const {
      currentTarget: { scrollTop, clientHeight, scrollHeight },
    } = event
    const scrollArrivingToEnd = ((scrollTop + clientHeight) / scrollHeight) * 100

    if (orderList && orderList?.length > 0 && !loadingList && !stopInfinitScroll && scrollArrivingToEnd >= END_REACHED_THRESHOLD_PERCENTAGE) {
      getOrder(startDate, endDate, true)
    }
  }

  useEffect(() => {
    getOrder(startDate, endDate)
  }, [orderType])

  useEffect(() => {
    if (!printOrder) return
    handleShowPrint(null, () => printOrderRef.current)
  }, [printOrder])

  useEffect(() => {
    if (!orderListPrintSummary || (orderListPrintSummary || []).length === 0) return
    const oderToPrint: any[] = []
    const taxPercent = Number(company?.taxPercent || 0)

    let paymentTotalAmt = 0
    let paymentTotalVat = 0
    let paymentTotal = 0

    let noPaymentTotalAmt = 0
    let noPaymentTotalVat = 0
    let noPaymentTotal = 0

    let hasPayment = false

    orderListPrintSummary.forEach((item, index) => {
      const totalPrice = Number(item.totalPrice)
      const vat = discontPercent({ price: totalPrice, percent: taxPercent })

      const data = {
        Key: (index + 1).toString(),
        Date: moment(item.dateCreatedAt).format('DD/MM/YYYY'),
        Type: item.status === 'RETURNED' ? 'Product Credit Note' : 'Product Invoice (From SAP)',
        "Invoice Number": item?.invoiceNumber?.toString() || '',
        "Cliente Name": item.clientName,
        Amount: formatNumberToLibra({ value: totalPrice, showCurrency: true }),
        VAT: formatNumberToLibra({ value: vat, showCurrency: true }),
        Total: formatNumberToLibra({ value: totalPrice + vat, showCurrency: true }),
        "Payment Recived": item.paymentRef ? 'Yes' : 'No'
      }
      if (item.paymentRef) {
        hasPayment = true
        paymentTotalAmt = truncateByDecimalPlace({ value: paymentTotalAmt + totalPrice })
        paymentTotalVat += vat
        paymentTotal = truncateByDecimalPlace({ value: paymentTotal + (totalPrice + vat) })
      } else {
        noPaymentTotalAmt = truncateByDecimalPlace({ value: noPaymentTotalAmt + totalPrice })
        noPaymentTotalVat += vat
        noPaymentTotal = truncateByDecimalPlace({ value: noPaymentTotal + (totalPrice + vat) })
      }
      oderToPrint.push(data)
    });

    const ws = XLSX.utils.aoa_to_sheet([
      [`${company?.name || ''} - Invoice Summary`, '', '', '', '', '', '', '', '', '', '', 'Total paid'],
      [moment().format('dddd, MMMM Do YYYY, h:mm:ss a')],
      [""],
      [""],
      ["", '', '', '', '', '', '', '', '', '', '', 'Total unpaid'],
    ]);

    const mergeA1 = { s: { r: 0, c: 0 }, e: { r: 0, c: 8 } };
    const mergeA2 = { s: { r: 1, c: 0 }, e: { r: 1, c: 8 } };
    const mergeA3 = { s: { r: 2, c: 0 }, e: { r: 2, c: 8 } };
    const mergeL1 = { s: { r: 0, c: 11 }, e: { r: 0, c: 13 } };
    const mergeL5 = { s: { r: 4, c: 11 }, e: { r: 4, c: 13 } };

    if (!ws['!merges']) ws['!merges'] = [];
    ws['!merges'].push(mergeA1);
    ws['!merges'].push(mergeA2);
    ws['!merges'].push(mergeA3);
    ws['!merges'].push(mergeL1);
    ws['!merges'].push(mergeL5);

    XLSX.utils.sheet_add_json(ws, [{
      "Total Amt": formatNumberToLibra({ value: paymentTotalAmt, showCurrency: true }),
      "Total VAT": formatNumberToLibra({ value: paymentTotalVat, showCurrency: true }),
      "Total": formatNumberToLibra({ value: paymentTotal, showCurrency: true })
    },
    ], { origin: "L2" });

    XLSX.utils.sheet_add_json(ws, [
      {
        "Total Amt": formatNumberToLibra({ value: noPaymentTotalAmt, showCurrency: true }),
        "Total VAT": formatNumberToLibra({ value: noPaymentTotalVat, showCurrency: true }),
        "Total": formatNumberToLibra({ value: noPaymentTotal, showCurrency: true })
      },
    ], { origin: "L6" });

    XLSX.utils.sheet_add_json(ws, oderToPrint, { origin: "A4" });

    const styleHeader = {
      fill: { bgColor: { rgb: '00000000' } },
      font: { sz: 13, color: { rgb: "FFFFFFFF" } },
    };
    const styleHeaderTotal = {
      fill: { bgColor: { rgb: '00000000' } },
      font: { sz: 13, color: { rgb: "FFFFFFFF" } },
    };
    const styleHeaderTotalBorder = {
      border: {
        right: {
          style: "thin",
          color: "000000"
        },
        left: {
          style: "thin",
          color: "000000"
        },
        top: {
          style: "thin",
          color: "000000"
        },
        bottom: {
          style: "thin",
          color: "000000"
        }
      }
    };

    ws.A1.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 16, bold: true, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };
    ws.A2.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 13, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };
    ws.A3.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 13, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };

    if (hasPayment) {
      ws.A4.s = styleHeader;
      ws.B4.s = styleHeader;
      ws.C4.s = styleHeader;
      ws.D4.s = styleHeader;
      ws.E4.s = styleHeader;
      ws.F4.s = styleHeader;
      ws.G4.s = styleHeader;
      ws.H4.s = styleHeader;
      ws.I4.s = styleHeader;
    }

    ws.L1.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 16, bold: true, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };
    ws.L2.s = styleHeaderTotal;
    ws.L3.s = styleHeaderTotalBorder;
    ws.M2.s = styleHeaderTotal;
    ws.M3.s = styleHeaderTotalBorder;
    ws.N2.s = styleHeaderTotal;
    ws.N3.s = styleHeaderTotalBorder;

    ws.L5.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 16, bold: true, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };
    ws.L6.s = styleHeaderTotal;
    ws.L7.s = styleHeaderTotalBorder;
    ws.M6.s = styleHeaderTotal;
    ws.M7.s = styleHeaderTotalBorder;
    ws.N6.s = styleHeaderTotal;
    ws.N7.s = styleHeaderTotalBorder;

    const wscols = [
      { wch: 6 },
      { wch: 14 },
      { wch: 26 },
      { wch: 18 },
      { wch: 30 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 16 },

      { wch: 2 },
      { wch: 2 },

      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    ws['!cols'] = wscols;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `invoice_summary_${moment().valueOf()}.xlsx`);

    setIsOpenPrintInvoiceSummary(false)
    setLoadingPrintInvoiceSummary(false)
    formikFilterInvoiceSummary.resetForm()

  }, [orderListPrintSummary])

  return (
    <Box>
      <Breadcrumb pageTitle='Orders' menu={breadcrumbList} />

      <BoxShadow>
        <Stack display='flex' flex={1} flexDirection='row' justifyContent='space-between' padding={2}>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            showEndDate={showEndDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setShowEndDate={setShowEndDate}
            onSearch={() => {
              if (!startDate || !endDate) return
              getOrder(startDate, endDate)
            }}
            disabledBtnSearch={!hasFilterDates}
          />

          <Stack display='flex' flexDirection='row'>
            <FormControl size='small'>
              <InputLabel id='filterBy'>Filter by</InputLabel>
              <Select
                labelId='filterBy'
                value={filterBy}
                label='Filter by'
                onChange={handleChangeOrderBy}
                sx={{ width: 180 }}
              >
                <MenuItem value=''>
                  <em>-- --</em>
                </MenuItem>
                {Object.entries(ENUM_ORDER_BY).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack marginTop={1} mx={0.2}>-</Stack>

            {filterBy !== 'FLOW' && (
              <TextField
                size='small'
                label='Search'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <TbSearch color={grey[900]} />
                    </InputAdornment>
                  ),
                }}
                value={searchOrder}
                onChange={event => {
                  setSearchOrder(event.target.value)
                }}
                sx={{ width: 180 }}
                disabled={!hasFilterBy}
              ></TextField>
            )}

            {filterBy === 'FLOW' && (
              <FormControl size='small'>
                <InputLabel id='filterBy'>Select</InputLabel>
                <Select
                  labelId='Flow'
                  value={filterFlowBy}
                  label='Select'
                  onChange={handleChangeFilterFlowBy}
                  sx={{ width: 180 }}
                >
                  {Object.entries(EnumOrderStatus).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {orderType !== 'QUOTATION' && (
              <Tooltip title='Print invoice summary' sx={{ ml: 2 }}>
                <IconButton onClick={() => { setIsOpenPrintInvoiceSummary(true) }}>
                  <TbFileTypeXls color={grey[900]} />
                </IconButton>
              </Tooltip>
            )}

            <Button
              size='small'
              variant='outlined'
              disableElevation
              onClick={() => {
                setFilterBy('')
                setSearchOrder('')
                setStartDate(null)
                setEndDate(null)
                getOrder(null, null)
              }}
              sx={{ height: 37, ml: 1 }}
              disabled={!hasSomeFilter}
            >
              Clean
            </Button>

          </Stack>
        </Stack>
        <TableContainer
          ref={refTable}
          sx={{ height: BOX_HEIGHT }}
          onScroll={(event) => {
            if (stopInfinitScroll) return
            onScroll(event)
          }}
        >
          <Table stickyHeader sx={{ position: 'relative' }} size='small'>
            <TableHead>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Typography variant='subtitle2'>Service info</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Sale nº</Typography>
                </TableCell>
                <TableCell >
                  <Typography variant='subtitle2'>Invoice nº</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Created</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Flow</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Client</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Price/Hour</Typography>
                </TableCell>
                <TableCell >
                  <Typography variant='subtitle2'>Payment</Typography>
                </TableCell>
                <TableCell width={50} />
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
          {loadingMore && <LoadingTable height={100} position='relative' />}
        </TableContainer>
      </BoxShadow>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            navigate(RoutesNames.ORDER_CRUD, {
              state: {
                orderSelected: JSON.stringify(orderSelected),
                from: routesNameByOrderType
              },
            })
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            handlePrint()
          }}
        >
          <ListItemIcon>
            <TbPrinter size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'  >
            Print order
          </Typography>
        </MenuItem>

        {['OPEN', 'DECLINED'].includes(orderSelected?.status || '') && (
          <MenuItem
            onClick={() => {
              handleCloseMenuAction()
              setIsOpenApproveQuotation(true)
            }}
          >
            <ListItemIcon>
              <TbCheck size={20} color={grey[900]} />
            </ListItemIcon>
            <Typography variant='body2'  >
              Approve quotation
            </Typography>
          </MenuItem>
        )}

        {orderSelected?.status === 'ACCEPTED' && orderSelected?.type === 'QUOTATION' && (
          <MenuItem
            onClick={() => {
              handleCloseMenuAction()
              setIsOpenConfirmChangeOrderType(true)
            }}
          >
            <ListItemIcon>
              <TbSwitch3 size={20} color={grey[900]} />
            </ListItemIcon>
            <Typography variant='body2'  >
              Change to Sale
            </Typography>
          </MenuItem>
        )}
        {orderSelected?.status === 'ACCEPTED' && orderSelected?.type === 'SALE' && (
          <MenuItem
            onClick={() => {
              handleCloseMenuAction()
              setIsOpenConfirmChangeOrderType(true)
            }}
          >
            <ListItemIcon>
              <TbSwitch3 size={20} color={grey[900]} />
            </ListItemIcon>
            <Typography variant='body2'  >
              Change to Invoice and Credits
            </Typography>
          </MenuItem>
        )}

        {/* <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            setIsOpenConfirmDelete(true)
          }}
          disabled={orderSelected?.type !== 'QUOTATION'}
        >
          <ListItemIcon>
            <TbTrash size={20} color={red[500]} />
          </ListItemIcon>
          <Typography variant='body2' color={red[500]}>
            Delete
          </Typography>
        </MenuItem> */}
      </Menu>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => { },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setIsOpenConfirmDelete(false)
          },
        }}
      />

      <DialogConfirm
        title='Change type'
        description={`Do you really change to ${orderSelected?.type === 'QUOTATION' ? `"Sale"` : `"Inovices & Credits"`}?`}

        open={isOpenConfirmChangeOrderType}
        onCloseDialog={() => {
          setIsOpenConfirmChangeOrderType(false)
          setLoadingChangeOrderType(false)
        }}
        btnOkProps={{
          onClick: () => {
            handleChangeOrderType()
          },
          loading: loadingChangeOrderType,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setOrderSelected(undefined)
            setIsOpenConfirmChangeOrderType(false)
          },
        }}
      />

      <DialogConfirm
        title='Quotation'
        description={`Do you really want approve quotation?`}
        open={isOpenApproveQuotation}
        onCloseDialog={() => {
          setIsOpenApproveQuotation(false)
        }}
        btnOkProps={{
          onClick: () => { handleApproveQuotation() },
          loading: loadingApproveQuotation,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setIsOpenApproveQuotation(false)
          },
        }}
      >
        <Stack mt={2}>
          <Alert severity="warning">When approving the quotation, the selected parts will be deducted from stock.</Alert>
        </Stack>
      </DialogConfirm>

      <DialogConfirm
        title='Filter'
        description={`Choose the data you want to filter`}
        open={isOpenPrintInvoiceSummary}
        onCloseDialog={() => {
          setIsOpenPrintInvoiceSummary(false)
          formikFilterInvoiceSummary.resetForm()
        }}
        btnOkProps={{
          onClick: () => { formikFilterInvoiceSummary.submitForm() },
          loading: loadingPrintInvoiceSummary,
        }}
        btnOkTitle='Filter'
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setIsOpenPrintInvoiceSummary(false)
            formikFilterInvoiceSummary.resetForm()
          },
        }}
      >
        <FormikProvider value={formikFilterInvoiceSummary}>
          <Form>
            <Grid container spacing={2} rowSpacing={3} mt={0.5}>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer components={['DatePicker']}>
                    <FormControl fullWidth>
                      <DatePicker
                        slotProps={{
                          textField: {
                            name: 'startDate',
                            error: formikFilterInvoiceSummary.touched.startDate && Boolean(formikFilterInvoiceSummary.errors.startDate),
                            helperText: (formikFilterInvoiceSummary.touched.startDate && Boolean(formikFilterInvoiceSummary.errors.startDate)) ? formikFilterInvoiceSummary.errors.startDate?.toString() || '' : ''
                          },
                          field: {
                            clearable: true,
                            onClear: () => { formikFilterInvoiceSummary.setFieldValue('startDate', null) },
                          },
                        }}
                        format='DD/MM/YYYY'
                        label="Start date"
                        name='startDate'
                        value={formikFilterInvoiceSummary.values.startDate ? moment(formikFilterInvoiceSummary.values.startDate) : null}
                        onChange={(date) => {
                          if (!date) {
                            formikFilterInvoiceSummary.setFieldValue('startDate', null, false)
                            formikFilterInvoiceSummary.setFieldTouched('startDate', true)
                          } else {
                            formikFilterInvoiceSummary.setFieldValue('startDate', date.valueOf())
                            formikFilterInvoiceSummary.setFieldValue('endDate', null)
                          }
                        }}
                      />
                    </FormControl>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer components={['DatePicker']}>
                    <FormControl fullWidth>
                      <DatePicker
                        slotProps={{
                          textField: {
                            name: 'endDate',
                            error: formikFilterInvoiceSummary.touched.endDate && Boolean(formikFilterInvoiceSummary.errors.endDate),
                            helperText: (formikFilterInvoiceSummary.touched.endDate && Boolean(formikFilterInvoiceSummary.errors.endDate)) ? formikFilterInvoiceSummary.errors.endDate?.toString() || '' : ''
                          },
                          field: {
                            clearable: true,
                            onClear: () => { formikFilterInvoiceSummary.setFieldValue('endDate', null) },
                          },
                        }}
                        format='DD/MM/YYYY'
                        label="End date"
                        name='endDate'
                        value={formikFilterInvoiceSummary.values.endDate ? moment(formikFilterInvoiceSummary.values.endDate) : null}
                        onChange={(date) => {
                          if (!date) {
                            formikFilterInvoiceSummary.setFieldValue('endDate', null, false)
                            formikFilterInvoiceSummary.setFieldTouched('endDate', true)
                          } else {
                            formikFilterInvoiceSummary.setFieldValue('endDate', date.valueOf())
                          }
                        }}
                        minDate={formikFilterInvoiceSummary?.values.startDate ? moment(formikFilterInvoiceSummary?.values.startDate) : undefined}
                      />
                    </FormControl>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogConfirm>

      {printOrder?.client && printOrder?.service && printOrder?.order && (
        <div
          style={{ visibility: 'hidden', height: 0 }}>
          <PrintOrder
            ref={printOrderRef}
            client={printOrder.client}
            service={printOrder.service}
            order={printOrder.order}
          />
        </div>
      )}
    </Box>
  )
}
